
.favorite {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);

    .leftBox {
        width: 100%;

        .tab_box {
            display: flex;
            width: 100%;

            ::v-deep .el-tabs {
                height: 100%;
                width: 100%;
            }

            ::v-deep .el-tabs__header {
                border-bottom: none;
                font-size: 14px;
                margin: 0;
            }

            ::v-deep .el-tabs__content {
                height: 1%;
                flex: 1;
                background: #fff;
                padding-top: 20px;
                margin-bottom: 20px;
            }

            ::v-deep .el-tabs__header .is-active {
                background: #fff;
                font-size: 14px;
                font-weight: 400;
                color: #4D5AFF;
                border-radius: 6px 6px 0 0;
            }

            ::v-deep .el-tabs__item {
                border: none;
                height: 52px;
                line-height: 52px;
            }

            ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
                border: none;
            }

            ::v-deep .el-tab-pane {
                height: 100%;
            }
        }
    }
}

