
.activeClass {
    background: #2338E6;
    color: #ffffff;
}

.company-contain {
    margin-bottom: 20px;
    background-color: #ffffff;

    .rank-title {
        width: 26px;
        height: 26px;
        background: #E0E2FF;
        border-radius: 50%;
        text-align: center;
        font-size: 18px;
        line-height: 26px;
    }

    .operation {
        display: flex;
        justify-content: center;
        align-items: center;

        .detail-btn {
            width: 80px;
            height: 36px;
            background: #FFFFFF;
            border: 1px solid #20C997;
            color: #20C997;
            border-radius: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .showing {
        display: flex;
        align-items: center;
        /*width: 30px;*/
        height: 30px;
        margin-left: 20px;

        img {
            display: inline-block;
            height: 30px;
            width: 30px;
            margin-right: 10px;
            border-radius: 50%;
        }

        .showing-name {
            max-width: 260px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }


    ::v-deep .el-table {
        display: flex;
        flex-direction: column;
        height: calc(100% - 114px);
    }

    ::v-deep .el-table--border::after, ::v-deep .el-table--group::after, ::v-deep .el-table::before {
        background-color: transparent;
    }

    ::v-deep .el-table--medium td, ::v-deep .el-table--medium th {
        padding: 10px 9px;
    }

    ::v-deep .el-table__footer-wrapper, ::v-deep .el-table__header-wrapper {
        overflow: initial;
    }

    ::v-deep .el-table__body-wrapper {
        overflow: auto;
    }

}
