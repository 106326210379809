
.activeClass {
    background: #2338E6;
    color: #ffffff;
}

.company-contain {
    margin-bottom: 20px;
    background-color: #ffffff;

    .tab_box {
        ::v-deep .el-tabs__header {
            display: flex;
            justify-content: center;
            border-bottom: 1px solid #EEEEEE !important;
            background: #ffffff !important;
        }

        ::v-deep .el-tabs__header .is-active {
            border-bottom: 2px solid #4D5AFF !important;
        }

        ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
            display: flex !important;
            justify-content: space-around !important;
            width: 300px !important;
        }
    }
}
